





















































import Page from '@/components/swine/layout/Page.vue';
import SearchBar from '@/components/swine/SearchBar.vue';
import Dropdown from '@/components/swine/Dropdown.vue';
import UserTable from './UserTable.vue';
import { UserStores } from '@/store/swine/user/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  DEFAULT_COUNT_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_USER_FILTERS,
  DEFAULT_SPECIES_OPTIONS,
} from '@/utils/constants';
import { User } from '@/models/swine/user/user.model';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Role } from '@/utils/constants';
import { UserSessionStore } from '@/store/swine/user-session';
import { CountryStores } from '@/store/swine/country';
import { generateUniqueId } from '@/utils/lodash';
import GeneralPage from '@/components/general/layout/GeneralPage.vue';
import { ProfileStores } from '@/store/swine/profile';

@Component({
  components: {
    Page,
    SearchBar,
    Dropdown,
    UserTable,
    GeneralPage,
  },
})
export default class UserManagement extends Vue {
  store = UserStores.list;
  userList: User[] = [];
  totalUsers = 0;
  filteredUsers = 0;
  roles = DEFAULT_USER_FILTERS;
  currentPageNumber = DEFAULT_PAGE_NUMBER;
  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  offsetValue = 0;
  currentRoleFilter = '';
  currentCountryFilter = '';
  currentSpeciesFilter = '';
  search = '';
  sortBy = 'createdDate:desc';
  countries: DropdownOption[] = [];
  showCountryFilter = false;
  currentRole = '';
  userSessionDetails = UserSessionStore.detail;
  showCreateButton = true;
  countryStore = CountryStores.list;
  profileStore = ProfileStores.profile;
  species = DEFAULT_SPECIES_OPTIONS;

  @Watch(`countryStore.response`)
  retrieveCountries() {
    if (this.countryStore.response) {
      this.countries = [];
      this.countries.push({
        text: 'All',
        value: '',
        uniqueKey: `all`,
      });
      this.countryStore.response.map((data) => {
        this.countries.push({
          text: data.name,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.name}_`),
        });
      });
    }
  }

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.profileStore.userProfile) {
      this.retrieveUsers();
      this.countryStore.getAllCountries();
      this.currentRole = this.profileStore.userProfile.role;
      if (this.currentRole === Role.Regional) {
        this.showCountryFilter = true;
      }
      if (
        this.currentRole === Role.Auditor ||
        this.currentRole === Role.Viewer
      ) {
        this.showCreateButton = false;
      }
    }
  }

  @Watch('store.paginationResponse')
  updateInformation() {
    if (this.store.paginationResponse) {
      this.userList = this.store.paginationResponse.users;
      this.totalUsers = this.store.paginationResponse.totalCount;
      this.filteredUsers = this.store.paginationResponse.filteredCount;
    }
  }

  changeRoleFilter(role: DropdownOption) {
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = 0;
    this.currentRoleFilter = role!.value;
    this.retrieveUsers();
  }

  changeCountryFilter(role: DropdownOption) {
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = 0;
    this.currentCountryFilter = role!.value;
    this.retrieveUsers();
  }
  changeSpeciesFilter(species: DropdownOption) {
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = 0;
    this.currentSpeciesFilter = species!.value;
    this.retrieveUsers();
  }

  searchUser(searchQuery: string) {
    this.search = searchQuery;
    this.retrieveUsers();
  }

  sortTable(sort: string) {
    this.sortBy = sort;
    this.retrieveUsers();
  }

  navigateToCreate() {
    this.$router.push({
      name: 'CreateUser',
    });
  }

  updateRowCount(params: RowCountChange) {
    this.currentRowsPerPage = params.rowsPerPage;
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = params.offsetValue;
    this.retrieveUsers();
  }

  updatePageNumber(params: PageNumberChange) {
    this.currentPageNumber = params.pageNumber;
    this.offsetValue = params.offsetValue;
    this.retrieveUsers();
  }

  retrieveUsers() {
    const baseSearchParams = {
      limit: this.currentRowsPerPage,
      offset: this.offsetValue,
      filterBy: this.currentRoleFilter,
      country: this.currentCountryFilter,
      sortBy: this.sortBy,
      species: this.currentSpeciesFilter,
    };

    if (this.search === '') {
      this.store.getPaginatedUsers(baseSearchParams);
    } else {
      this.store.searchUsers({
        ...baseSearchParams,
        search: this.search,
      });
    }
  }
}
